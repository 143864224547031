.templateList1 .operationControl {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
}
.templateList1 .mabtm {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
}
.templateList1 .searchboxItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.templateList1 .searchboxItem .el-cascader {
  height: 32px !important;
}
.templateList1 .searchboxItem .itemLabel {
  width: 100px;
}
.exportdialog .el-dialog__title {
  font-size: 16px;
}
.exportdialog .export-box {
  width: 100%;
  margin-bottom: 20px;
}
.exportdialog .export-box h1 {
  padding: 0 5px 10px;
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  border-bottom: 1px dashed #ccc;
}
.exportdialog .export-box h1 span {
  margin-left: 20px;
  font-size: 14px;
}
.exportdialog .export-box > div {
  padding-left: 30px;
}
.exportdialog .export-box .el-button.is-disabled,
.exportdialog .export-box .el-button.is-disabled:focus,
.exportdialog .export-box .el-button.is-disabled:hover {
  color: #fff;
  background: #c3c3c3;
  border: none;
}
